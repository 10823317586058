
.icon-check:before {
  content: "\e900";
}
.icon-close:before {
  content: "\e901";
}
.icon-next:before {
  content: "\e902";
}
.icon-phone:before {
  content: "\e903";
}
.icon-play:before {
  content: "\e904";
}
.icon-prev:before {
  content: "\e905";
}
.icon-quote:before {
  content: "\e906";
}
.icon-search:before {
  content: "\e907";
}
