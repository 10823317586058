.footer {
	padding: 14rem 0 1.6rem;
	background-size: cover;
	order: 3;
	position: relative;
	flex-shrink: 0;
	z-index: 0;

	.wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
	position: relative;
	z-index: 0;
		&:after {
			display: none;
		}
	}
	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
    background-size: cover;
    background-position: 50% 0%;
    background-repeat: no-repeat;
	}
}

.logo-footer {
	transition: none;
	img {
	height: 5rem;
	vertical-align: top;
}
}
.btn_started {
	margin-left: 9%
}

.footer-menu {
	padding: 0 0 0 3rem;
	ul {
		list-style: none;
	display: flex;
	}
	li {
		margin-left: 4.1rem;
	}
	a {
font-size: 1.6rem;
line-height: 170%;

transition: color .4s ease;

color: #646A89;
&:hover, &.current {
	color: $color-active
}
	}

}
.footer-social, .footer-phone {
	margin: 0 2.2rem;
}
.footer-social {
	padding-bottom: .2rem;
}
.footer-title {
font-size: 1.4rem;
line-height: 140%;

margin-right: 2rem;

}
.footer-phone__item a {
	font-size: 1.6rem;

transition: none;
}
.footer-phone__item  i {
	color: $color-active;
	font-size: 1.7rem;
	margin-right: .9rem;
}
.footer-phone .footer-title {
	margin-right: 3rem;
}
.footer-bottom {
	border-top: 1px solid #DBDFF3;
	margin-top: 1.9rem;
	padding-top: 1.5rem;
}
.copyrights {
font-size: 1.4rem;
line-height: 170%;

color: #646A89;
}
.footer-2 {
	padding-top: 5.1rem;
	.footer__bg {
		top: -22rem;
	}
}
@media screen and (max-width: 1366px) {
.btn_started {
    margin-left: 7%;
}
}
@media screen and (max-width: 1100px) {
.btn_started {
    margin-left: 3rem;
}
}
@media screen and (max-width: 1023px) {
	.footer {

	padding-top: 6rem;
	background-position: 87% 0
	}
.footer .wrapper {
	flex-wrap: wrap;
}
.logo-footer {
	order: 1;
}
.footer-social{
	margin: 2.5rem 2rem 0 0;

	order: 3;
	}.footer-phone {
	margin: 2.5rem 0 0 2rem;
		order: 4;

}
.btn_started {
	order: 2;
	margin-left: 50%
}
.footer-bottom .wrapper{
	flex-direction: column-reverse;
	align-items: center;
}
.footer-menu {
	margin: 0 -1.2rem;
    padding: 0 0 1.7rem;
    width: 100%;
    ul {
    	justify-content: center;
    }

}
.footer-menu li {
    margin: 0 1.2rem
}
}
@media screen and (max-width: 767px) {
.footer {
	background: linear-gradient(286.98deg, #DBDFF3 0.84%, rgba(241, 249, 255, 0.1) 94.29%) !important;
	padding: 3rem 0 1.6rem;
	text-align: center;
}
.footer__bg {
    display: none
}
.footer .wrapper {
	justify-content: center;
}
.btn_started {
    margin: 2rem auto 0;
    width: 36rem;
    max-width: 100%
}
.footer-phone {
	margin: 2rem 0 0;
	width: 100%;
	display: block;
}
.footer-phone .footer-title {
    margin: 0 0 .5rem;
}
.footer-social {
    margin: 2.5rem 0 0;
 }
 .logo-footer {
 margin-top: 2rem
 }
}
@media screen and (max-width: 559px) {
.footer-menu ul {
	display: block;
}
}
